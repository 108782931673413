import React from "react"
import styled from "styled-components"

const Wrapper = styled.footer`
  margin: 6rem 0 1rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  text-align: center;
  color: ${props => props.theme.colors.grey};
  a {
    text-decoration: none;
    color: ${props => props.theme.brand.primary};
  }
`

const Footer = () => (
  <Wrapper data-testid="footer">
    <p>
      Interested in Working Together? <a href="#">Contact Us</a>
    </p>
    Copyright &copy; 2019{" "}
    <a href="https://github.com/LekoArts/gatsby-starter-portfolio-emma">
      PRIMARY
    </a>{" "}
    {" by "} <a href="https://www.lekoarts.de/en">LekoArts</a>.
  </Wrapper>
)

export default Footer
