module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Primary', // Navigation and Site Title
  siteTitleAlt:
    'Boston Area Marketing Agency | Brand, Creative, Digital, 3D | Primary', // Alternative Site title for SEO
  siteHeadline: 'We believe powerful ideas transform anything', // Headline for schema.org JSONLD
  siteTitleShort: 'Primary', // short_name for manifest
  siteUrl: 'https://primary360.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription:
    'We deliver strategy, design, 3D, web, creative, media/social and analytics to drive consumer action for brands.',
  author: 'Primary', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@primary360', // Twitter Username
  ogSiteName: 'Primary360', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-117510296-1',

  // Manifest and Progress color
  themeColor: '#00afa9',
  backgroundColor: '#2b2e3c'
};
